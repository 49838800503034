<template>
  <div class="m_common_content">
    <div class="m_common_top">
      <div class="m_checkdata_content">
        <el-input
          v-model="inputValue"
          placeholder="请输入策略名称"
          clearable
        ></el-input>
        <el-button @click="search" class="m_btn_search">搜索</el-button>
      </div>
      <div class="m_tools_content">
        <el-button @click="showInsert" class="m_add m_btn_common"
          >添加</el-button
        >
      </div>
    </div>
    <div class="m_common_middle">
      <div class="m_table_content">
        <el-table :data="tableData" stripe>
          <el-table-column
            label="序号"
            type="index"
            width="60"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="name" label="策略名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="alertValue"
            label="预警温度"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <i
                class="el-icon-edit-outline m_edit"
                @click="showUpdate(scope.row)"
                title="编辑"
              ></i>
              <i
                class="el-icon-circle-plus-outline m_edit"
                @click="showEquipmentVisible(scope.row)"
                title="添加设备"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="m_common_bottom">
      <m-pagination @pageChange="pageChange" :total="total"></m-pagination>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :modal="false"
      v-el-drag-dialog
      width="600px"
    >
      <div class="m_dialog_top" :style="dialogTopStyle">
        <el-form
          ref="form"
          :model="form"
          label-width="auto"
          :rules="rules"
          style="padding-right: 10px"
        >
          <el-form-item label="策略名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="预警温度" prop="alertValue">
            <el-input type="number" v-model="form.alertValue"></el-input>
          </el-form-item>
          <el-form-item label="策略描述" prop="note">
            <el-input v-model="form.note"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="m_dialog_bottom">
        <el-button type="primary" @click="save" size="mini" class="m_btn_save"
          >保存</el-button
        >
        <el-button
          @click="dialogVisible = false"
          size="mini"
          class="m_btn_cancel"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="配置设备"
      :visible.sync="equipmentVisible"
      :modal="false"
      v-el-drag-dialog
      width="400px"
    >
      <div class="m_dialog_top" :style="dialogTopStyle">
        <el-tree
          :data="treeData"
          :props="treeProps"
          ref="tree"
          node-key="id"
          highlight-current
          show-checkbox
          :expand-on-click-node="false"
          @check="equipmentCheck"
        >
        </el-tree>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog";
import mPagination from "@/components/pagination/index.vue";
import { PAGE_SIZE } from "@/utils/config";
import {
  temperaturesSelect,
  temperaturesInsert,
  temperaturesUpdate,
  temperaturesDelete,
  temperaturesMachinesSelect,
  temperaturesMachinesUpdate,
} from "@/api/vmtempControl";
import { machinesSelect } from "@/api/equipment";
import regulars from "@/utils/regular";
export default {
  directives: { elDragDialog },
  data() {
    return {
      treeData: [],
      treeProps: {
        children: "children",
        label: "name",
      },
      total: 0,
      currentPage: 1,
      emperatures: null,
      inputValue: null,
      tableData: [],
      map: null,
      dialogTitle: "添加",
      dialogVisible: false,
      equipmentVisible: false,
      rules: {
        alertValue: [
          { required: true, message: "预警温度不能为空", trigger: "blur" },
        ],
        name: [{ required: true, message: "策略名称不能为空", trigger: "blur" }],
        note: [{ required: true, message: "策略描述不能为空", trigger: "blur" }],
      },
      form: {
        id: 0,
        name: "",
        alertValue: "",
        note: "",
      },
    };
  },
  props: {},
  computed: {
    pageSize() {
      return PAGE_SIZE;
    },
    dialogTopStyle() {
      return {
        maxHeight: document.body.offsetHeight * 0.6 + "px",
      };
    },
  },
  components: {
    mPagination,
  },
  created() {
    this.select();
    this.machinesSelect();
  },
  mounted() {},
  methods: {
    equipmentCheck(checkedNodes) {
      this.temperaturesMachinesUpdate({
        temperatureId: this.emperatures.id,
        machineId: checkedNodes.id,
        enabled: this.$refs.tree.getNode(checkedNodes).checked,
      });
    },
    showEquipmentVisible(data) {
      if (this.$refs.tree) {
        this.$refs.tree.setCheckedKeys([]);
      }
      this.emperatures = data;
      this.equipmentVisible = true;
      this.temperaturesMachinesSelect();
    },
    pageChange(page) {
      this.currentPage = page;
      this.select();
    },
    search() {},
    showInsert() {
      this.initForm();
      this.dialogTitle = "添加";
      this.dialogVisible = true;
    },
    showUpdate(data) {
      this.initForm();
      this.dialogTitle = "修改";
      this.form = data;
      this.dialogVisible = true;
    },
    initForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.form = {
        id: 0,
        name: "",
        alertValue: "",
        note: "",
      };
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          switch (this.dialogTitle) {
            case "添加":
              this.insert();
              break;
            case "修改":
              this.update();
              break;
          }
        } else {
          return false;
        }
      });
    },
    async select() {
      const res = await temperaturesSelect({
        name: this.inputValue,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      });
      const { pageCount, results } = res;
      this.tableData = results;
      this.total = pageCount.allCount;
    },
    async insert() {
      const res = await temperaturesInsert(this.form);
      const { pageCount, results } = res;
      this.$message({
        message: "添加成功",
        type: "success",
      });
      this.select();
      this.dialogVisible = false;
    },
    async update() {
      const res = await temperaturesUpdate(this.form);
      const { pageCount, results } = res;
      this.$message({
        message: "修改成功",
        type: "success",
      });
      this.select();
      this.dialogVisible = false;
    },
    async delete() {
      const res = await temperaturesDelete();
      const { pageCount, results } = res;
    },
    async machinesSelect() {
      const res = await machinesSelect();
      const { pageCount, results } = res;
      console.log(results,'results')
      this.treeData = results;
      
    },
    async temperaturesMachinesSelect() {
      const res = await temperaturesMachinesSelect({
        id: this.emperatures.id
      });
      const { pageCount, results } = res;
      let list = [];
      results.forEach((item) => {
        list.push(item.machineId);
      });
      this.$refs.tree.setCheckedKeys(list);
    },
    async temperaturesMachinesUpdate(data) {
      const res = await temperaturesMachinesUpdate(data);
      const { pageCount, results } = res;
      this.$message({
        message: "修改成功",
        type: "success",
      });
    },
  },
  beforeDestroy() {},
  destroyed() {},
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common/common.less";
</style>
<style lang="less">
@import "../../assets/css/checkdata/checkdata.less";
</style>
