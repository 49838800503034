import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询设备温控策略
export const temperaturesSelect = data => request(`${XHR_URL}/api/v1/temperatures`, data, "GET");
// 新增设备温控策略
export const temperaturesInsert = data => request(`${XHR_URL}/api/v1/temperatures`, data, "POST");
// 修改设备温控策略
export const temperaturesUpdate = data => request(`${XHR_URL}/api/v1/temperatures`, data, "PUT");
// 删除设备温控策略
export const temperaturesDelete = data => request(`${XHR_URL}/api/v1/temperatures`, data, "DELETE");
// 查询设备温控策略
export const temperaturesMachinesSelect = data => request(`${XHR_URL}/api/v1/temperatures/machines`, data, "GET");
// 查询设备温控策略
export const temperaturesMachinesUpdate = data => request(`${XHR_URL}/api/v1/temperatures/machines`, data, "PUT");
